import { createTheme, responsiveFontSizes } from "@mui/material";

// Declare custom color types for theme pallets
declare module "@mui/material/styles" {
  interface CustomPalette {
    impact: string,
    accent: string,
    strongbackground: string,
    plainbackground: string,
    dropshadow: string,
  }
  interface Palette extends CustomPalette {}
  interface PaletteOptions extends CustomPalette {}
}

const theme = responsiveFontSizes(createTheme({
    // TODO: Dark mode support (swap pictures too? night picture with dark mode)

    // palette: {
    //   mode: 'dark',
    // },

    // palette: {
    //   primary: {  // Used for links and other primary state stuff
    //     main: '#2b2fff',
    //   },
    //   impact: "#ff912b",
    //   accent: "#99ff2b",
    //   strongbackground: "#fffa2b",
    //   plainbackground: "#ffffff",
    //   dropshadow: "#00000050",
    // },

    typography: {
      fontFamily: [
        'Heiti SC',
        'Helvetica',
        'sans-serif'
      ].join(','),
    },

    components: {
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 10,
            textTransform: "none",
          },
        },
      }, 
    },

}));

export default theme;
